

// import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import {
//   BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine
// } from 'recharts';
// import '../Team.css';

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

// function Compare2Teams() {
//   const query = useQuery();
//   const team1 = query.get('team1');
//   const team2 = query.get('team2');

//   const [propSelection, setPropSelection] = useState('Goal Difference');
//   const [subPropSelection, setSubPropSelection] = useState('');
//   const [teamFilter, setTeamFilter] = useState('All');
//   const [timeFilter, setTimeFilter] = useState('Last 5');
//   const [altLine1, setAltLine1] = useState(1);
//   const [altLine2, setAltLine2] = useState(1);
//   const [team1Info, setTeam1Info] = useState([]);
//   const [team2Info, setTeam2Info] = useState([]);
//   const [chartData1, setChartData1] = useState([]);
//   const [chartData2, setChartData2] = useState([]);
//   const [hitRate1, setHitRate1] = useState(0);
//   const [hitRate2, setHitRate2] = useState(0);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     const fetchTeamData = async (teamName, setTeamInfo) => {
//       try {
//         const response = await fetch(`http://localhost:5550/teamlist_info/${encodeURIComponent(teamName)}`);
//         const data = await response.json();
//         const sortedData = data.sort((a, b) => new Date(b['Match Time']) - new Date(a['Match Time']));
//         setTeamInfo(sortedData);
//       } catch (error) {
//         setError(`Error fetching team info for ${teamName}: ${error.message}`);
//       }
//     };

//     fetchTeamData(team1, setTeam1Info);
//     fetchTeamData(team2, setTeam2Info);
//   }, [team1, team2]);

//   useEffect(() => {
//     const processTeamData = (teamData, setChartData, setHitRate, altLine) => {
//       let filteredData = teamData;

//       // Apply filters
//       filteredData = filteredData.filter(info => {
//         if (teamFilter === 'Home') return info['Home Team'] === team1 || info['Home Team'] === team2;
//         if (teamFilter === 'Away') return info['Away Team'] === team1 || info['Away Team'] === team2;
//         return true;
//       });

//       // Apply time filter
//       const currentDate = new Date();
//       if (timeFilter === 'Last 5') {
//         filteredData = filteredData.slice(0, 5);
//       } else if (timeFilter === 'Last 10') {
//         filteredData = filteredData.slice(0, 10);
//       } else if (timeFilter === 'Last 20') {
//         filteredData = filteredData.slice(0, 20);
//       } else if (timeFilter === '22/23') {
//         filteredData = filteredData.filter(info => {
//           const matchDate = new Date(info['Match Time']);
//           return matchDate >= new Date('2022-05-19') && matchDate <= new Date('2023-08-25');
//         });
//       } else if (timeFilter === '23/24') {
//         filteredData = filteredData.filter(info => {
//           const matchDate = new Date(info['Match Time']);
//           return matchDate >= new Date('2023-05-19') && matchDate <= new Date('2024-08-25');
//         });
//       } else if (timeFilter === '24/25') {
//         filteredData = filteredData.filter(info => {
//           const matchDate = new Date(info['Match Time']);
//           return matchDate >= new Date('2024-05-19') && matchDate <= new Date('2025-08-25');
//         });
//       }

//       const updatedData = filteredData.map(info => {
//         const isHomeTeam = info['Home Team'] === team1 || info['Home Team'] === team2;
//         const homeGoals = parseInt(info['Home Goals Count'], 10);
//         const awayGoals = parseInt(info['Away Goals Count'], 10);

//         let entry = {
//           MatchTime: info['Match Time'],
//           HomeTeam: info['Home Team'],
//           AwayTeam: info['Away Team'],
//           Score: `${homeGoals}:${awayGoals}`,
//           Value: 0 // Default value, will be updated
//         };

//         switch (subPropSelection || propSelection) {
//           case 'Goal Difference':
//           case 'Total Goal Difference':
//             entry['Goal Difference'] = isHomeTeam ? homeGoals - awayGoals : awayGoals - homeGoals;
//             entry.Value = entry['Goal Difference'];
//             break;

//           case '1st Half Goal Difference':
//             const home1stHalfGoals = parseInt(info['Home 1st Half Goals'], 10);
//             const away1stHalfGoals = parseInt(info['Away 1st Half Goals'], 10);
//             entry['1st Half Goal Difference'] = isHomeTeam ? home1stHalfGoals - away1stHalfGoals : away1stHalfGoals - home1stHalfGoals;
//             entry.Value = entry['1st Half Goal Difference'];
//             break;
//           case '2nd Half Goal Difference':
//             const home2ndHalfGoals = parseInt(info['Home 2nd Half Goals'], 10);
//             const away2ndHalfGoals = parseInt(info['Away 2nd Half Goals'], 10);
//             entry['2nd Half Goal Difference'] = isHomeTeam ? home2ndHalfGoals - away2ndHalfGoals : away2ndHalfGoals - home2ndHalfGoals;
//             entry.Value = entry['2nd Half Goal Difference'];
//             break;
//           case 'Total Goals':
//             entry['Total Goals'] = homeGoals + awayGoals;
//             entry.Value = entry['Total Goals'];
//             break;
//           case 'Team Clean Sheet':
//             entry['Team Clean Sheet'] = (isHomeTeam && awayGoals === 0) || (!isHomeTeam && homeGoals === 0) ? 1 : 0;
//             entry.Value = entry['Team Clean Sheet'];
//             break;

//           case '1st Half BTTS':
//             entry['1st Half BTTS'] = info['1st Half BTTS'];
//             entry.Value = parseFloat(info['1st Half BTTS']); // Ensure it's passed as a number
//             break;
          
//           case '2nd Half BTTS':
//             entry['2nd Half BTTS'] = info['2nd Half BTTS'];
//             entry.Value = parseFloat(info['2nd Half BTTS']); // Ensure it's passed as a number
//             break;
          
//           case 'Total BTTS':
//             entry['Total BTTS'] = info['Total BTTS'];
//             entry.Value = parseFloat(info['Total BTTS']); // Ensure it's passed as a number
//             break;

//           case 'Home 1st Half Goals':
//             entry['Home 1st Half Goals'] = parseInt(info['Home 1st Half Goals'], 10);
//             entry.Value = entry['Home 1st Half Goals'];
//             break;
//           case 'Away 1st Half Goals':
//             entry['Away 1st Half Goals'] = parseInt(info['Away 1st Half Goals'], 10);
//             entry.Value = entry['Away 1st Half Goals'];
//             break;
//           case 'Away 2nd Half Goals':
//             entry['Away 2nd Half Goals'] = parseInt(info['Away 2nd Half Goals'], 10);
//             entry.Value = entry['Away 2nd Half Goals'];
//             break;
//           case 'Home Goals':
//             entry['Home Goals'] = parseInt(info['Home Goals Count'], 10);
//             entry.Value = entry['Home Goals'];
//             break;
//           case 'Away Goals':
//             entry['Away Goals'] = parseInt(info['Away Goals Count'], 10);
//             entry.Value = entry['Away Goals'];
//             break;
//           case 'Total 1st Half Goals':
//             entry['Total 1st Half Goals'] = parseInt(info['Home 1st Half Goals'], 10) + parseInt(info['Away 1st Half Goals'], 10);
//             entry.Value = entry['Total 1st Half Goals'];
//             break;
//           case 'Total 2nd Half Goals':
//             entry['Total 2nd Half Goals'] = parseInt(info['Home 2nd Half Goals'], 10) + parseInt(info['Away 2nd Half Goals'], 10);
//             entry.Value = entry['Total 2nd Half Goals'];
//             break;
//           case 'Home 2nd Half Goals':
//             entry['Home 2nd Half Goals'] = parseInt(info['Home 2nd Half Goals'], 10);
//             entry.Value = entry['Home 2nd Half Goals'];
//             break;
//           case 'Home 1st Half Corners':
//             entry['Home 1st Half Corners'] = parseInt(info['Home 1st Half Corners'], 10);
//             entry.Value = entry['Home 1st Half Corners'];
//             break;
//           case 'Away 1st Half Corners':
//             entry['Away 1st Half Corners'] = parseInt(info['Away 1st Half Corners'], 10);
//             entry.Value = entry['Away 1st Half Corners'];
//             break;
//           case 'Total 1st Half Corners':
//             entry['Total 1st Half Corners'] = parseInt(info['Home 1st Half Corners'], 10) + parseInt(info['Away 1st Half Corners'], 10);
//             entry.Value = entry['Total 1st Half Corners'];
//             break;
//           case 'Home 2nd Half Corners':
//             entry['Home 2nd Half Corners'] = parseInt(info['Home 2nd Half Corners'], 10);
//             entry.Value = entry['Home 2nd Half Corners'];
//             break;
//           case 'Away 2nd Half Corners':
//             entry['Away 2nd Half Corners'] = parseInt(info['Away 2nd Half Corners'], 10);
//             entry.Value = entry['Away 2nd Half Corners'];
//             break;
//           case 'Total 2nd Half Corners':
//             entry['Total 2nd Half Corners'] = parseInt(info['Home 2nd Half Corners'], 10) + parseInt(info['Away 2nd Half Corners'], 10);
//             entry.Value = entry['Total 2nd Half Corners'];
//             break;
//           case 'Home Corners':
//             entry['Home Corners'] = parseInt(info['Home Corners Count'], 10);
//             entry.Value = entry['Home Corners'];
//             break;
//           case 'Away Corners':
//             entry['Away Corners'] = parseInt(info['Away Corners Count'], 10);
//             entry.Value = entry['Away Corners'];
//             break;
//           case 'Total Corners':
//             entry['Total Corners'] = parseInt(info['Home Corners Count'], 10) + parseInt(info['Away Corners Count'], 10);
//             entry.Value = entry['Total Corners'];
//             break;
//           // Add other cases as needed
//           default:
//             entry.Value = null;
//             break;
//         }

//         return entry;
//       });

//       setChartData(updatedData.filter(item => item.Value !== null));
//       const hitCount = updatedData.filter(item => item.Value >= altLine).length;
//       const totalCount = updatedData.length;
//       const calculatedHitRate = totalCount > 0 ? (hitCount / totalCount) * 100 : 0;
//       setHitRate(calculatedHitRate.toFixed(2));
//     };

//     processTeamData(team1Info, setChartData1, setHitRate1, altLine1);
//     processTeamData(team2Info, setChartData2, setHitRate2, altLine2);
//   }, [team1Info, team2Info, propSelection, subPropSelection, teamFilter, timeFilter, altLine1, altLine2, team1, team2]);

//   const CustomRectangle = React.memo(({ x, y, width, height, value }) => {
//     // Ensure bars for zero values are visible by setting a minimum height
//     const adjustedHeight = value === 0 ? 5 : Math.abs(height); // Small height for zero values
//     const adjustedY = value >= 0 ? y : y + height; // Adjust y position for negative values
    
//     // Set color based on value: red for zero, green for positive, grey for negative
//     const fillColor = value === 0 ? "#ff4c60" : value > 0 ? "#4cff60" : "#808080"; // Grey for negative values
  
//     return (
//       <rect x={x} y={adjustedY} width={width} height={adjustedHeight} fill={fillColor} />
//     );
//   });
  
//   const CustomTooltip = ({ active, payload, label }) => {
//     if (active && payload && payload.length) {
//       const data = payload[0].payload;
//       return (
//         <div className="custom-tooltip">
//           <p className="label">{`Match Time: ${data.MatchTime}`}</p>
//           <p className="label">{`Home Team: ${data.HomeTeam}`}</p>
//           <p className="label">{`Away Team: ${data.AwayTeam}`}</p>
//           <p className="label">{`${subPropSelection || propSelection}: ${data.Value}`}</p>
//         </div>
//       );
//     }
//     return null;
//   };

//   const renderChart = (chartData, altLine) => (
//     <ResponsiveContainer width="100%" height={400}>
//       <BarChart data={chartData}>
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="MatchTime" />
//         <YAxis />
//         <Tooltip content={<CustomTooltip />} />
//         <Bar dataKey="Value" shape={<CustomRectangle />} />
//         <ReferenceLine y={altLine} stroke="grey" strokeDasharray="3 3" />
//       </BarChart>
//     </ResponsiveContainer>
//   );

//   const renderTable = (teamData) => (
//     <table className="styled-table">
//       <thead>
//         <tr>
//           <th>Match Time</th>
//           <th>Home Team</th>
//           <th>Away Team</th>
//           <th>Score</th>
//           {propSelection && <th>{subPropSelection || propSelection}</th>}
//         </tr>
//       </thead>
//       <tbody>
//         {teamData.map((info, index) => (
//           <tr key={index}>
//             <td>{info.MatchTime}</td>
//             <td>{info.HomeTeam}</td>
//             <td>{info.AwayTeam}</td>
//             <td>{info.Score}</td>
//             {propSelection && (
//               <td>
//                 {info[subPropSelection || propSelection]}
//               </td>
//             )}


            
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   );

//   const renderSubPropMenu = () => {
//     switch (propSelection) {
//       case 'Goal Difference':
//         return (
//           <div className="sub-prop-menu">
//             <button className={subPropSelection === '1st Half Goal Difference' ? 'active' : ''} onClick={() => setSubPropSelection('1st Half Goal Difference')}>1st Half Goal Difference</button>
//             <button className={subPropSelection === '2nd Half Goal Difference' ? 'active' : ''} onClick={() => setSubPropSelection('2nd Half Goal Difference')}>2nd Half Goal Difference</button>
//             <button className={subPropSelection === 'Total Goal Difference' ? 'active' : ''} onClick={() => setSubPropSelection('Total Goal Difference')}>Total Goal Difference</button>
//           </div>
//         );
//       case 'Goals':
//         return (
//           <div className="sub-prop-menu">
//             <button className={subPropSelection === 'Home 1st Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Home 1st Half Goals')}>Home 1st Half Goals</button>
//             <button className={subPropSelection === 'Away 1st Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Away 1st Half Goals')}>Away 1st Half Goals</button>
//             <button className={subPropSelection === 'Total Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Total Goals')}>Total Goals</button>
//             <button className={subPropSelection === 'Away 2nd Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Away 2nd Half Goals')}>Away 2nd Half Goals</button>
//             <button className={subPropSelection === 'Home Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Home Goals')}>Home Goals</button>
//             <button className={subPropSelection === 'Away Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Away Goals')}>Away Goals</button>
//             <button className={subPropSelection === 'Total 1st Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Total 1st Half Goals')}>Total 1st Half Goals</button>
//             <button className={subPropSelection === 'Total 2nd Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Total 2nd Half Goals')}>Total 2nd Half Goals</button>
//             <button className={subPropSelection === 'Home 2nd Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Home 2nd Half Goals')}>Home 2nd Half Goals</button>
//           </div>
//         );
//       case 'BTTS':
//         return (
//           <div className="sub-prop-menu">
//             <button className={subPropSelection === '1st Half BTTS' ? 'active' : ''} onClick={() => setSubPropSelection('1st Half BTTS')}>1st Half BTTS</button>
//             <button className={subPropSelection === '2nd Half BTTS' ? 'active' : ''} onClick={() => setSubPropSelection('2nd Half BTTS')}>2nd Half BTTS</button>
//             <button className={subPropSelection === 'Total BTTS' ? 'active' : ''} onClick={() => setSubPropSelection('Total BTTS')}>Total BTTS</button>
//           </div>
//         );
//       case 'Corners':
//         return (
//           <div className="sub-prop-menu">
//             <button className={subPropSelection === 'Home 1st Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Home 1st Half Corners')}>Home 1st Half Corners</button>
//             <button className={subPropSelection === 'Away 1st Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Away 1st Half Corners')}>Away 1st Half Corners</button>
//             <button className={subPropSelection === 'Total 1st Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Total 1st Half Corners')}>Total 1st Half Corners</button>
//             <button className={subPropSelection === 'Home 2nd Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Home 2nd Half Corners')}>Home 2nd Half Corners</button>
//             <button className={subPropSelection === 'Away 2nd Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Away 2nd Half Corners')}>Away 2nd Half Corners</button>
//             <button className={subPropSelection === 'Total 2nd Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Total 2nd Half Corners')}>Total 2nd Half Corners</button>
//             <button className={subPropSelection === 'Home Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Home Corners')}>Home Corners</button>
//             <button className={subPropSelection === 'Away Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Away Corners')}>Away Corners</button>
//             <button className={subPropSelection === 'Total Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Total Corners')}>Total Corners</button>
//           </div>
//         );
//       case 'Team Clean Sheet':
//         return (
//           <div className="sub-prop-menu">
//             <button className={subPropSelection === 'Team Clean Sheet' ? 'active' : ''} onClick={() => setSubPropSelection('Team Clean Sheet')}>Team Clean Sheet</button>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="content-container">
//       <h1>{team1} vs {team2} Comparison</h1>
//       {error && <p className="error-message">{error}</p>}

//       {/* Prop Selection Filter Buttons */}
//       <nav className="navbar">
//         <ul className="nav-list">
//           <li className={`nav-item ${propSelection === 'Goal Difference' ? 'active' : ''}`} onClick={() => setPropSelection('Goal Difference')}>
//             Goal Difference
//           </li>
//           <li className={`nav-item ${propSelection === 'Goals' ? 'active' : ''}`} onClick={() => setPropSelection('Goals')}>
//             Goals
//           </li>
//           <li className={`nav-item ${propSelection === 'BTTS' ? 'active' : ''}`} onClick={() => setPropSelection('BTTS')}>
//             BTTS
//           </li>
//           <li className={`nav-item ${propSelection === 'Corners' ? 'active' : ''}`} onClick={() => setPropSelection('Corners')}>
//             Corners
//           </li>
//           <li className={`nav-item ${propSelection === 'Team Clean Sheet' ? 'active' : ''}`} onClick={() => setPropSelection('Team Clean Sheet')}>
//             Team Clean Sheet
//           </li>
//         </ul>
//       </nav>

//       {renderSubPropMenu()}

//       {/* Team Filter Buttons */}
//       <nav className="navbar">
//         <ul className="nav-list">
//           <li className={`nav-item ${teamFilter === 'All' ? 'active' : ''}`} onClick={() => setTeamFilter('All')}>Overall</li>
//           <li className={`nav-item ${teamFilter === 'Home' ? 'active' : ''}`} onClick={() => setTeamFilter('Home')}>Home</li>
//           <li className={`nav-item ${teamFilter === 'Away' ? 'active' : ''}`} onClick={() => setTeamFilter('Away')}>Away</li>
//         </ul>
//       </nav>

//       {/* Time Filter Buttons */}
//       <nav className="navbar">
//         <ul className="nav-list">
//           <li className={`nav-item ${timeFilter === 'Last 5' ? 'active' : ''}`} onClick={() => setTimeFilter('Last 5')}>Last 5</li>
//           <li className={`nav-item ${timeFilter === 'Last 10' ? 'active' : ''}`} onClick={() => setTimeFilter('Last 10')}>Last 10</li>
//           <li className={`nav-item ${timeFilter === 'Last 20' ? 'active' : ''}`} onClick={() => setTimeFilter('Last 20')}>Last 20</li>
//           <li className={`nav-item ${timeFilter === '22/23' ? 'active' : ''}`} onClick={() => setTimeFilter('22/23')}>22/23</li>
//           <li className={`nav-item ${timeFilter === '23/24' ? 'active' : ''}`} onClick={() => setTimeFilter('23/24')}>23/24</li>
//           <li className={`nav-item ${timeFilter === '24/25' ? 'active' : ''}`} onClick={() => setTimeFilter('24/25')}>24/25</li>
//         </ul>
//       </nav>

//       {/* Team comparison display */}
//       <div className="team-comparison-wrapper">
//         <div className="team-half team-left">
//           <h2>{team1}</h2>
//           <div className="alt-line-controls">
//             <span className="alt-line-label">Alt Line 1:</span>
//             <input
//               type="number"
//               className="alt-line-input"
//               value={altLine1}
//               onChange={(e) => setAltLine1(parseFloat(e.target.value))}
//               step="0.25"
//               min="0"
//             />
//           </div>
//           <p>Hit Rate: {hitRate1}%</p>
//           {team1Info.length > 0 ? (
//             <>
//               {renderChart(chartData1, altLine1)}
//               {renderTable(chartData1)}
//             </>
//           ) : (
//             <p>No data available for {team1}.</p>
//           )}
//         </div>

//         <div className="team-half team-right">
//           <h2>{team2}</h2>
//           <div className="alt-line-controls">
//             <span className="alt-line-label">Alt Line 2:</span>
//             <input
//               type="number"
//               className="alt-line-input"
//               value={altLine2}
//               onChange={(e) => setAltLine2(parseFloat(e.target.value))}
//               step="0.25"
//               min="0"
//             />
//           </div>
//           <p>Hit Rate: {hitRate2}%</p>
//           {team2Info.length > 0 ? (
//             <>
//               {renderChart(chartData2, altLine2)}
//               {renderTable(chartData2)}
//             </>
//           ) : (
//             <p>No data available for {team2}.</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Compare2Teams;










import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine
} from 'recharts';
import '../Team.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Compare2Teams() {
  const query = useQuery();
  const team1 = query.get('team1');
  const team2 = query.get('team2');

  const [propSelection, setPropSelection] = useState('Goal Difference');
  const [subPropSelection, setSubPropSelection] = useState('');
  const [teamFilter, setTeamFilter] = useState('All');
  const [timeFilter, setTimeFilter] = useState('Last 5');
  const [altLine1, setAltLine1] = useState(1);
  const [altLine2, setAltLine2] = useState(1);
  const [team1Info, setTeam1Info] = useState([]);
  const [team2Info, setTeam2Info] = useState([]);
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [hitRate1, setHitRate1] = useState(0);
  const [hitRate2, setHitRate2] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTeamData = async (teamName, setTeamInfo) => {
      try {
        const response = await fetch(`https://api.betsavvy.ai/teamlist_info/${encodeURIComponent(teamName)}`);
        const data = await response.json();
        const sortedData = data.sort((a, b) => new Date(b['Match Time']) - new Date(a['Match Time']));
        setTeamInfo(sortedData);
      } catch (error) {
        setError(`Error fetching team info for ${teamName}: ${error.message}`);
      }
    };

    fetchTeamData(team1, setTeam1Info);
    fetchTeamData(team2, setTeam2Info);
  }, [team1, team2]);

  useEffect(() => {
    const processTeamData = (teamData, setChartData, setHitRate, altLine) => {
      let filteredData = teamData;

      // Apply filters
      filteredData = filteredData.filter(info => {
        if (teamFilter === 'Home') return info['Home Team'] === team1 || info['Home Team'] === team2;
        if (teamFilter === 'Away') return info['Away Team'] === team1 || info['Away Team'] === team2;
        return true;
      });

      // Apply time filter
      const currentDate = new Date();
      if (timeFilter === 'Last 5') {
        filteredData = filteredData.slice(0, 5);
      } else if (timeFilter === 'Last 10') {
        filteredData = filteredData.slice(0, 10);
      } else if (timeFilter === 'Last 20') {
        filteredData = filteredData.slice(0, 20);
      } else if (timeFilter === '22/23') {
        filteredData = filteredData.filter(info => {
          const matchDate = new Date(info['Match Time']);
          return matchDate >= new Date('2022-05-19') && matchDate <= new Date('2023-08-25');
        });
      } else if (timeFilter === '23/24') {
        filteredData = filteredData.filter(info => {
          const matchDate = new Date(info['Match Time']);
          return matchDate >= new Date('2023-05-19') && matchDate <= new Date('2024-08-25');
        });
      } else if (timeFilter === '24/25') {
        filteredData = filteredData.filter(info => {
          const matchDate = new Date(info['Match Time']);
          return matchDate >= new Date('2024-05-19') && matchDate <= new Date('2025-08-25');
        });
      }

      const updatedData = filteredData.map(info => {
        const isHomeTeam = info['Home Team'] === team1 || info['Home Team'] === team2;
        const homeGoals = parseInt(info['Home Goals Count'], 10);
        const awayGoals = parseInt(info['Away Goals Count'], 10);

        let entry = {
          MatchTime: info['Match Time'],
          HomeTeam: info['Home Team'],
          AwayTeam: info['Away Team'],
          Score: `${homeGoals}:${awayGoals}`,
          Value: 0 // Default value, will be updated
        };

        switch (subPropSelection || propSelection) {
          case 'Goal Difference':
          case 'Total Goal Difference':
            entry['Goal Difference'] = isHomeTeam ? homeGoals - awayGoals : awayGoals - homeGoals;
            entry.Value = entry['Goal Difference'];
            break;

          case '1st Half Goal Difference':
            const home1stHalfGoals = parseInt(info['Home 1st Half Goals'], 10);
            const away1stHalfGoals = parseInt(info['Away 1st Half Goals'], 10);
            entry['1st Half Goal Difference'] = isHomeTeam ? home1stHalfGoals - away1stHalfGoals : away1stHalfGoals - home1stHalfGoals;
            entry.Value = entry['1st Half Goal Difference'];
            break;
          case '2nd Half Goal Difference':
            const home2ndHalfGoals = parseInt(info['Home 2nd Half Goals'], 10);
            const away2ndHalfGoals = parseInt(info['Away 2nd Half Goals'], 10);
            entry['2nd Half Goal Difference'] = isHomeTeam ? home2ndHalfGoals - away2ndHalfGoals : away2ndHalfGoals - home2ndHalfGoals;
            entry.Value = entry['2nd Half Goal Difference'];
            break;
          case 'Total Goals':
            entry['Total Goals'] = homeGoals + awayGoals;
            entry.Value = entry['Total Goals'];
            break;
          case 'Team Clean Sheet':
            entry['Team Clean Sheet'] = (isHomeTeam && awayGoals === 0) || (!isHomeTeam && homeGoals === 0) ? 1 : 0;
            entry.Value = entry['Team Clean Sheet'];
            break;

          case '1st Half BTTS':
            entry['1st Half BTTS'] = info['1st Half BTTS'];
            entry.Value = parseFloat(info['1st Half BTTS']); // Ensure it's passed as a number
            break;
          
          case '2nd Half BTTS':
            entry['2nd Half BTTS'] = info['2nd Half BTTS'];
            entry.Value = parseFloat(info['2nd Half BTTS']); // Ensure it's passed as a number
            break;
          
          case 'Total BTTS':
            entry['Total BTTS'] = info['Total BTTS'];
            entry.Value = parseFloat(info['Total BTTS']); // Ensure it's passed as a number
            break;

          case 'Home 1st Half Goals':
            entry['Home 1st Half Goals'] = parseInt(info['Home 1st Half Goals'], 10);
            entry.Value = entry['Home 1st Half Goals'];
            break;
          case 'Away 1st Half Goals':
            entry['Away 1st Half Goals'] = parseInt(info['Away 1st Half Goals'], 10);
            entry.Value = entry['Away 1st Half Goals'];
            break;
          case 'Away 2nd Half Goals':
            entry['Away 2nd Half Goals'] = parseInt(info['Away 2nd Half Goals'], 10);
            entry.Value = entry['Away 2nd Half Goals'];
            break;
          case 'Home Goals':
            entry['Home Goals'] = parseInt(info['Home Goals Count'], 10);
            entry.Value = entry['Home Goals'];
            break;
          case 'Away Goals':
            entry['Away Goals'] = parseInt(info['Away Goals Count'], 10);
            entry.Value = entry['Away Goals'];
            break;
          case 'Total 1st Half Goals':
            entry['Total 1st Half Goals'] = parseInt(info['Home 1st Half Goals'], 10) + parseInt(info['Away 1st Half Goals'], 10);
            entry.Value = entry['Total 1st Half Goals'];
            break;
          case 'Total 2nd Half Goals':
            entry['Total 2nd Half Goals'] = parseInt(info['Home 2nd Half Goals'], 10) + parseInt(info['Away 2nd Half Goals'], 10);
            entry.Value = entry['Total 2nd Half Goals'];
            break;
          case 'Home 2nd Half Goals':
            entry['Home 2nd Half Goals'] = parseInt(info['Home 2nd Half Goals'], 10);
            entry.Value = entry['Home 2nd Half Goals'];
            break;
          case 'Home 1st Half Corners':
            entry['Home 1st Half Corners'] = parseInt(info['Home 1st Half Corners'], 10);
            entry.Value = entry['Home 1st Half Corners'];
            break;
          case 'Away 1st Half Corners':
            entry['Away 1st Half Corners'] = parseInt(info['Away 1st Half Corners'], 10);
            entry.Value = entry['Away 1st Half Corners'];
            break;
          case 'Total 1st Half Corners':
            entry['Total 1st Half Corners'] = parseInt(info['Home 1st Half Corners'], 10) + parseInt(info['Away 1st Half Corners'], 10);
            entry.Value = entry['Total 1st Half Corners'];
            break;
          case 'Home 2nd Half Corners':
            entry['Home 2nd Half Corners'] = parseInt(info['Home 2nd Half Corners'], 10);
            entry.Value = entry['Home 2nd Half Corners'];
            break;
          case 'Away 2nd Half Corners':
            entry['Away 2nd Half Corners'] = parseInt(info['Away 2nd Half Corners'], 10);
            entry.Value = entry['Away 2nd Half Corners'];
            break;
          case 'Total 2nd Half Corners':
            entry['Total 2nd Half Corners'] = parseInt(info['Home 2nd Half Corners'], 10) + parseInt(info['Away 2nd Half Corners'], 10);
            entry.Value = entry['Total 2nd Half Corners'];
            break;
          case 'Home Corners':
            entry['Home Corners'] = parseInt(info['Home Corners Count'], 10);
            entry.Value = entry['Home Corners'];
            break;
          case 'Away Corners':
            entry['Away Corners'] = parseInt(info['Away Corners Count'], 10);
            entry.Value = entry['Away Corners'];
            break;
          case 'Total Corners':
            entry['Total Corners'] = parseInt(info['Home Corners Count'], 10) + parseInt(info['Away Corners Count'], 10);
            entry.Value = entry['Total Corners'];
            break;
          // Add other cases as needed
          default:
            entry.Value = null;
            break;
        }

        return entry;
      });

      setChartData(updatedData.filter(item => item.Value !== null));
      const hitCount = updatedData.filter(item => item.Value >= altLine).length;
      const totalCount = updatedData.length;
      const calculatedHitRate = totalCount > 0 ? (hitCount / totalCount) * 100 : 0;
      setHitRate(calculatedHitRate.toFixed(2));
    };

    processTeamData(team1Info, setChartData1, setHitRate1, altLine1);
    processTeamData(team2Info, setChartData2, setHitRate2, altLine2);
  }, [team1Info, team2Info, propSelection, subPropSelection, teamFilter, timeFilter, altLine1, altLine2, team1, team2]);

  const CustomRectangle = React.memo(({ x, y, width, height, value }) => {
    // Ensure bars for zero values are visible by setting a minimum height
    const adjustedHeight = value === 0 ? 5 : Math.abs(height); // Small height for zero values
    const adjustedY = value >= 0 ? y : y + height; // Adjust y position for negative values
    
    // Set color based on value: red for zero, green for positive, grey for negative
    const fillColor = value === 0 ? "#ff4c60" : value > 0 ? "#4cff60" : "#808080"; // Grey for negative values
  
    return (
      <rect x={x} y={adjustedY} width={width} height={adjustedHeight} fill={fillColor} />
    );
  });
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="label">{`Match Time: ${data.MatchTime}`}</p>
          <p className="label">{`Home Team: ${data.HomeTeam}`}</p>
          <p className="label">{`Away Team: ${data.AwayTeam}`}</p>
          <p className="label">{`${subPropSelection || propSelection}: ${data.Value}`}</p>
        </div>
      );
    }
    return null;
  };

  const renderChart = (chartData, altLine) => (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="MatchTime" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="Value" shape={<CustomRectangle />} />
        <ReferenceLine y={altLine} stroke="grey" strokeDasharray="3 3" />
      </BarChart>
    </ResponsiveContainer>
  );

  const renderTable = (teamData) => (
    <table className="styled-table">
      <thead>
        <tr>
          <th>Match Time</th>
          <th>Home Team</th>
          <th>Away Team</th>
          <th>Score</th>
          {propSelection && <th>{subPropSelection || propSelection}</th>}
        </tr>
      </thead>
      <tbody>
        {teamData.map((info, index) => (
          <tr key={index}>
            <td>{info.MatchTime}</td>
            <td>{info.HomeTeam}</td>
            <td>{info.AwayTeam}</td>
            <td>{info.Score}</td>
            {propSelection && (
              <td>
                {info[subPropSelection || propSelection]}
              </td>
            )}


            
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderSubPropMenu = () => {
    switch (propSelection) {
      case 'Goal Difference':
        return (
          <div className="sub-prop-menu">
            <button className={subPropSelection === '1st Half Goal Difference' ? 'active' : ''} onClick={() => setSubPropSelection('1st Half Goal Difference')}>1st Half Goal Difference</button>
            <button className={subPropSelection === '2nd Half Goal Difference' ? 'active' : ''} onClick={() => setSubPropSelection('2nd Half Goal Difference')}>2nd Half Goal Difference</button>
            <button className={subPropSelection === 'Total Goal Difference' ? 'active' : ''} onClick={() => setSubPropSelection('Total Goal Difference')}>Total Goal Difference</button>
          </div>
        );
      case 'Goals':
        return (
          <div className="sub-prop-menu">
            <button className={subPropSelection === 'Home 1st Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Home 1st Half Goals')}>Home 1st Half Goals</button>
            <button className={subPropSelection === 'Away 1st Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Away 1st Half Goals')}>Away 1st Half Goals</button>
            <button className={subPropSelection === 'Total Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Total Goals')}>Total Goals</button>
            <button className={subPropSelection === 'Away 2nd Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Away 2nd Half Goals')}>Away 2nd Half Goals</button>
            <button className={subPropSelection === 'Home Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Home Goals')}>Home Goals</button>
            <button className={subPropSelection === 'Away Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Away Goals')}>Away Goals</button>
            <button className={subPropSelection === 'Total 1st Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Total 1st Half Goals')}>Total 1st Half Goals</button>
            <button className={subPropSelection === 'Total 2nd Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Total 2nd Half Goals')}>Total 2nd Half Goals</button>
            <button className={subPropSelection === 'Home 2nd Half Goals' ? 'active' : ''} onClick={() => setSubPropSelection('Home 2nd Half Goals')}>Home 2nd Half Goals</button>
          </div>
        );
      case 'BTTS':
        return (
          <div className="sub-prop-menu">
            <button className={subPropSelection === '1st Half BTTS' ? 'active' : ''} onClick={() => setSubPropSelection('1st Half BTTS')}>1st Half BTTS</button>
            <button className={subPropSelection === '2nd Half BTTS' ? 'active' : ''} onClick={() => setSubPropSelection('2nd Half BTTS')}>2nd Half BTTS</button>
            <button className={subPropSelection === 'Total BTTS' ? 'active' : ''} onClick={() => setSubPropSelection('Total BTTS')}>Total BTTS</button>
          </div>
        );
      case 'Corners':
        return (
          <div className="sub-prop-menu">
            <button className={subPropSelection === 'Home 1st Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Home 1st Half Corners')}>Home 1st Half Corners</button>
            <button className={subPropSelection === 'Away 1st Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Away 1st Half Corners')}>Away 1st Half Corners</button>
            <button className={subPropSelection === 'Total 1st Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Total 1st Half Corners')}>Total 1st Half Corners</button>
            <button className={subPropSelection === 'Home 2nd Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Home 2nd Half Corners')}>Home 2nd Half Corners</button>
            <button className={subPropSelection === 'Away 2nd Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Away 2nd Half Corners')}>Away 2nd Half Corners</button>
            <button className={subPropSelection === 'Total 2nd Half Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Total 2nd Half Corners')}>Total 2nd Half Corners</button>
            <button className={subPropSelection === 'Home Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Home Corners')}>Home Corners</button>
            <button className={subPropSelection === 'Away Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Away Corners')}>Away Corners</button>
            <button className={subPropSelection === 'Total Corners' ? 'active' : ''} onClick={() => setSubPropSelection('Total Corners')}>Total Corners</button>
          </div>
        );
      case 'Team Clean Sheet':
        return (
          <div className="sub-prop-menu">
            <button className={subPropSelection === 'Team Clean Sheet' ? 'active' : ''} onClick={() => setSubPropSelection('Team Clean Sheet')}>Team Clean Sheet</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="content-container">
      <h1>{team1} vs {team2} Comparison</h1>
      {error && <p className="error-message">{error}</p>}

      {/* Prop Selection Filter Buttons */}
      <nav className="navbar">
        <ul className="nav-list">
          <li className={`nav-item ${propSelection === 'Goal Difference' ? 'active' : ''}`} onClick={() => setPropSelection('Goal Difference')}>
            Goal Difference
          </li>
          <li className={`nav-item ${propSelection === 'Goals' ? 'active' : ''}`} onClick={() => setPropSelection('Goals')}>
            Goals
          </li>
          <li className={`nav-item ${propSelection === 'BTTS' ? 'active' : ''}`} onClick={() => setPropSelection('BTTS')}>
            BTTS
          </li>
          <li className={`nav-item ${propSelection === 'Corners' ? 'active' : ''}`} onClick={() => setPropSelection('Corners')}>
            Corners
          </li>
          <li className={`nav-item ${propSelection === 'Team Clean Sheet' ? 'active' : ''}`} onClick={() => setPropSelection('Team Clean Sheet')}>
            Team Clean Sheet
          </li>
        </ul>
      </nav>

      {renderSubPropMenu()}

      {/* Team Filter Buttons */}
      <nav className="navbar">
        <ul className="nav-list">
          <li className={`nav-item ${teamFilter === 'All' ? 'active' : ''}`} onClick={() => setTeamFilter('All')}>Overall</li>
          <li className={`nav-item ${teamFilter === 'Home' ? 'active' : ''}`} onClick={() => setTeamFilter('Home')}>Home</li>
          <li className={`nav-item ${teamFilter === 'Away' ? 'active' : ''}`} onClick={() => setTeamFilter('Away')}>Away</li>
        </ul>
      </nav>

      {/* Time Filter Buttons */}
      <nav className="navbar">
        <ul className="nav-list">
          <li className={`nav-item ${timeFilter === 'Last 5' ? 'active' : ''}`} onClick={() => setTimeFilter('Last 5')}>Last 5</li>
          <li className={`nav-item ${timeFilter === 'Last 10' ? 'active' : ''}`} onClick={() => setTimeFilter('Last 10')}>Last 10</li>
          <li className={`nav-item ${timeFilter === 'Last 20' ? 'active' : ''}`} onClick={() => setTimeFilter('Last 20')}>Last 20</li>
          <li className={`nav-item ${timeFilter === '22/23' ? 'active' : ''}`} onClick={() => setTimeFilter('22/23')}>22/23</li>
          <li className={`nav-item ${timeFilter === '23/24' ? 'active' : ''}`} onClick={() => setTimeFilter('23/24')}>23/24</li>
          <li className={`nav-item ${timeFilter === '24/25' ? 'active' : ''}`} onClick={() => setTimeFilter('24/25')}>24/25</li>
        </ul>
      </nav>

      {/* Team comparison display */}
      <div className="team-comparison-wrapper">
        <div className="team-half team-left">
          <h2>{team1}</h2>
          <div className="alt-line-controls">
            <span className="alt-line-label">Alt Line 1:</span>
            <input
              type="number"
              className="alt-line-input"
              value={altLine1}
              onChange={(e) => setAltLine1(parseFloat(e.target.value))}
              step="0.25"
              min="0"
            />
          </div>
          <p>Hit Rate: {hitRate1}%</p>
          {team1Info.length > 0 ? (
            <>
              {renderChart(chartData1, altLine1)}
              {renderTable(chartData1)}
            </>
          ) : (
            <p>No data available for {team1}.</p>
          )}
        </div>

        <div className="team-half team-right">
          <h2>{team2}</h2>
          <div className="alt-line-controls">
            <span className="alt-line-label">Alt Line 2:</span>
            <input
              type="number"
              className="alt-line-input"
              value={altLine2}
              onChange={(e) => setAltLine2(parseFloat(e.target.value))}
              step="0.25"
              min="0"
            />
          </div>
          <p>Hit Rate: {hitRate2}%</p>
          {team2Info.length > 0 ? (
            <>
              {renderChart(chartData2, altLine2)}
              {renderTable(chartData2)}
            </>
          ) : (
            <p>No data available for {team2}.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Compare2Teams;
