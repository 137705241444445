

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import TeamList from './components/TeamList';
import TeamInfo from './components/TeamInfo';
import Compare2Teams from './components/Compare2Teams';
import Compare2Players from './components/Compare2Players';
import TodaysMatches from './components/TodaysMatches';
import './App.css';
import ReactGA from 'react-ga4';

// function App() {
//   useEffect(() => {
//     // ... Google Analytics code ...
//   }, []);
function App() {
  useEffect(() => {
    // Initialize Google Analytics with your Measurement ID
    ReactGA.initialize('G-FEFZR19FY9');
    // Send a pageview for the initial page load
    ReactGA.send('pageview');
  }, []);

  return (
    <Router>
      <div className="App">
        <Sidebar />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<TodaysMatches />} />
            {/* Other routes */}
            <Route path="/team-list" element={<TeamList />} />
            <Route path="/team/:teamName" element={<TeamInfo />} />
            <Route path="/compare/:player1/:player2" element={<Compare2Players />} />
            <Route path="/compare_teams" element={<Compare2Teams />} />
            {/* Ensure there are no references to Picks here */}
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
