import React, { useState } from 'react'; // Added useState for managing sidebar visibility
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaHome, FaList, FaLightbulb, FaParachuteBox, FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Import arrow icons

const SidebarContainer = styled.div`
  background: #1e1e2f;
  width: 200px; /* Reduced width */
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(${props => (props.isOpen ? '0' : '-100%')}); // Adjusted for sliding effect
  transition: transform 0.3s ease; // Added transition for smooth effect
`;

const SidebarHeader = styled.h2`
  color: #ecf0f1;
  text-align: center;
  margin: 20px 0;
  font-size: 1.4rem; /* Reduced font size */
  font-weight: 700;
`;

const SidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px 20px; /* Adjusted padding */
  font-size: 1.1rem; /* Reduced font size */
  color: #ecf0f1;
  text-decoration: none;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #34495e;
  }

  span {
    margin-left: 10px;
    font-weight: 500;
  }

  svg {
    font-size: 1.4rem; /* Adjusted icon size */
  }
`;

const ToggleButton = styled.button`
  position: absolute; // Positioning the button
  top: 20px; // Adjust as needed
  right: -30px; // Position it outside the sidebar
  z-index: 11; // Ensure it's above the sidebar
  background: #1e1e2f; // Changed button color to black
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`;

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage sidebar visibility

  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle sidebar visibility
  };

  return (
    <>
      <SidebarContainer isOpen={isOpen}>
        <ToggleButton onClick={toggleSidebar}>
          {isOpen ? <FaArrowLeft /> : <FaArrowRight />} {/* Arrow icon for toggle */}
        </ToggleButton>
        <SidebarHeader>Bet Savvy</SidebarHeader>
        <SidebarLink to='/'>
          <FaHome />
          <span>Today's Matches</span>
        </SidebarLink>
        <SidebarLink to='/team-list'>
          <FaList />
          <span>Team Stats</span>
        </SidebarLink>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
