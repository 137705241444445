
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './TeamList.css';

function TeamList() {
  const [events, setEvents] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('EPL');
  const [error, setError] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'Season', direction: 'asc' });
  const [availableSeasons, setAvailableSeasons] = useState([]);
  const [selectedSeasons, setSelectedSeasons] = useState([]);
  const [selectedOverGoalsFilter, setSelectedOverGoalsFilter] = useState('Over 0.5 Goals');
  const [selectedPercentageFilter, setSelectedPercentageFilter] = useState('Over 0.5 Percentage');
  const navigate = useNavigate();

  // Fetch available events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://api.betsavvy.ai/teams_by_all_events');
        if (response.data && typeof response.data === 'object') {
          const eventNames = Object.keys(response.data).filter(
            (eventName) =>
              eventName !== 'Ligue_1' &&
              eventName !== 'Ligue 1' &&
              eventName !== 'Serie A' &&
              eventName !== 'Portugal LigaPro'
          );
          setEvents(eventNames);

          if (eventNames.includes('EPL')) {
            setSelectedEvent('EPL');
          } else if (eventNames.length > 0) {
            setSelectedEvent(eventNames[0]);
          }
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (err) {
        setError(`Error fetching events: ${err.message}`);
      }
    };
    fetchEvents();
  }, []);

  // Fetch leaderboard for selected event
  useEffect(() => {
    const fetchLeaderboard = async () => {
      if (!selectedEvent) return;
      try {
        const response = await axios.get(
          `https://api.betsavvy.ai/leaderboard_by_event/${encodeURIComponent(selectedEvent)}`
        );
        const data = response.data;

        const seasons = [...new Set(data.map((item) => item.Season))].sort().reverse();
        setAvailableSeasons(seasons);
        setSelectedSeasons([seasons[0]]);

        setLeaderboard(data);
      } catch (err) {
        setError(`Error fetching leaderboard: ${err.message}`);
        setLeaderboard([]);
      }
    };
    fetchLeaderboard();
  }, [selectedEvent]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleSeasonFilterChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'All') {
      setSelectedSeasons(availableSeasons);
    } else {
      setSelectedSeasons([selectedValue]);
    }
  };

  const handleOverGoalsFilterChange = (e) => {
    setSelectedOverGoalsFilter(e.target.value);
  };

  const handlePercentageFilterChange = (e) => {
    setSelectedPercentageFilter(e.target.value);
  };

  const filteredLeaderboard = leaderboard.filter((item) =>
    selectedSeasons.includes(item.Season)
  );

  const sortedLeaderboard = [...filteredLeaderboard].sort((a, b) => {
    if (sortConfig.key) {
      const aVal = a[sortConfig.key];
      const bVal = b[sortConfig.key];
      if (sortConfig.direction === 'asc') {
        return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
      } else {
        return aVal < bVal ? 1 : aVal > bVal ? -1 : 0;
      }
    }
    return 0;
  });

  const handleTeamClick = (teamName) => {
    navigate(`/team/${encodeURIComponent(teamName)}`);
  };

  return (
    <div className="content-container">
      <header className="header">
        <h1 className="page-title">League Leaderboard</h1>
      </header>

      {error && <p className="error-message">{error}</p>}

      <nav className="navbar">
        <ul className="nav-list">
          {events.map((leagueName, index) => (
            <li
              key={index}
              className={`nav-item ${selectedEvent === leagueName ? 'active' : ''}`}
              onClick={() => setSelectedEvent(leagueName)}
            >
              {leagueName}
            </li>
          ))}
        </ul>
      </nav>

      <section className="leaderboard-section">
        <h2 className="section-title">{selectedEvent} Leaderboard</h2>

        {sortedLeaderboard.length > 0 ? (
          <table className="styled-table">
            <thead>
              <tr>
                <th>
                  <div className="header-filter">
                    Season
                    <select
                      className="header-dropdown"
                      onChange={handleSeasonFilterChange}
                      value={selectedSeasons[0] || 'All'}
                    >
                      <option value="All">All</option>
                      {availableSeasons.map((season) => (
                        <option key={season} value={season}>
                          {season}
                        </option>
                      ))}
                    </select>
                  </div>
                </th>
                <th onClick={() => handleSort('Team')}>Team</th>
                <th onClick={() => handleSort('Played')}>Played</th>
                <th>
                  <div className="header-filter">
                    Over Goals
                    <select
                      className="header-dropdown"
                      onChange={handleOverGoalsFilterChange}
                      value={selectedOverGoalsFilter}
                    >
                      <option value="Over 0.5 Goals">0.5</option>
                      <option value="Over 1.5 Goals">1.5</option>
                      <option value="Over 2.5 Goals">2.5</option>
                      <option value="Over 3.5 Goals">3.5</option>
                    </select>
                  </div>
                </th>
                <th>
                  <div className="header-filter">
                    Percentage
                    <select
                      className="header-dropdown"
                      onChange={handlePercentageFilterChange}
                      value={selectedPercentageFilter}
                    >
                      <option value="Over 0.5 Percentage">0.5</option>
                      <option value="Over 1.5 Percentage">1.5</option>
                      <option value="Over 2.5 Percentage">2.5</option>
                      <option value="Over 3.5 Percentage">3.5</option>
                    </select>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedLeaderboard.map((team, index) => (
                <tr key={index}>
                  <td>{team.Season}</td>
                  <td>
                    <span
                      className="clickable-team-name"
                      onClick={() => handleTeamClick(team.Team)}
                    >
                      {team.Team}
                    </span>
                  </td>
                  <td>{team.Played}</td>
                  <td>{team[selectedOverGoalsFilter]}</td>
                  <td>{team[selectedPercentageFilter]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No leaderboard data available.</p>
        )}
      </section>
    </div>
  );
}

export default TeamList;







