import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Line, ReferenceLine
} from 'recharts';
import '../Team.css';

function TeamInfo() {
  const { teamName } = useParams();

  const [propSelection, setPropSelection] = useState('Goal Difference');
  const [subPropSelection, setSubPropSelection] = useState('');
  const [teamFilter, setTeamFilter] = useState('All');
  const [timeFilter, setTimeFilter] = useState('Last 5');
  const [altLine, setAltLine] = useState(1);
  const [teamInfo, setTeamInfo] = useState([]);
  const [filteredTeamInfo, setFilteredTeamInfo] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [hitRate, setHitRate] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTeamInfo = async () => {
      try {
        const response = await axios.get(`https://api.betsavvy.ai/teamlist_info/${encodeURIComponent(teamName)}`);
        const sortedData = response.data.sort((a, b) => new Date(b['Match Time']) - new Date(a['Match Time']));
        setTeamInfo(sortedData);
        setFilteredTeamInfo(sortedData);
        setError('');
      } catch (error) {
        setError(`Error fetching team info: ${error.message}`);
        console.error('Error fetching team info:', error);
      }
    };
    fetchTeamInfo();
  }, [teamName]);



  useEffect(() => {
    let filteredData = teamInfo;
  
    // Apply team filter (All, Home, Away)
    filteredData = filteredData.filter(info => {
      if (teamFilter === 'Home') return info['Home Team'] === teamName;
      if (teamFilter === 'Away') return info['Away Team'] === teamName;
      return true;
    });
  
    // Ensure unique entries based on Match Time
    const uniqueData = Array.from(new Set(filteredData.map(info => info['Match Time'])))
      .map(matchTime => {
        return filteredData.find(info => info['Match Time'] === matchTime);
      });
  
    // Apply time filter
    const timeFilterMap = {
      'Last 5': 5,
      'Last 10': 10,
      'Last 20': 20
    };
  
    let finalData = uniqueData;
    if (timeFilter in timeFilterMap) {
      finalData = uniqueData.slice(0, timeFilterMap[timeFilter]);
    } else {
      const startDate = {
        '22/23': '2022-08-11',
        '23/24': '2023-08-11',
        '24/25': '2024-08-11',
      }[timeFilter];
  
      const endDate = {
        '22/23': '2023-05-19',
        '23/24': '2024-05-19',
        '24/25': '2025-05-19',
      }[timeFilter];
  
      finalData = uniqueData.filter(info => {
        const matchDate = new Date(info['Match Time']);
        return matchDate >= new Date(startDate) && matchDate <= new Date(endDate);
      });
    }
  
    // Prepare chart data and maintain table data
    const updatedData = finalData.map(info => {
      const isHomeTeam = info['Home Team'] === teamName;
  
      const homeGoals = parseInt(info['Home Goals Count'], 10);
      const awayGoals = parseInt(info['Away Goals Count'], 10);
      const homeFirstHalfGoals = parseInt(info['Home 1st Half Goals'], 10);
      const awayFirstHalfGoals = parseInt(info['Away 1st Half Goals'], 10);
      const homeSecondHalfGoals = parseInt(info['Home 2nd Half Goals'], 10);
      const awaySecondHalfGoals = parseInt(info['Away 2nd Half Goals'], 10);
      const totalGoals = parseInt(info['Total Goals'], 10);
      const totalFirstHalfGoals = parseInt(info['Total 1st Half Goals'], 10);
      const totalSecondHalfGoals = parseInt(info['Total 2nd Half Goals'], 10);
  
      // Ensure corners are non-negative and calculate `Total Corners` as the sum of both halves
      const totalFirstHalfCorners = Math.max(parseInt(info['Total 1st Half Corners'], 10), 0);
      const totalSecondHalfCorners = Math.max(parseInt(info['Total 2nd Half Corners'], 10), 0);
      const totalCorners = totalFirstHalfCorners + totalSecondHalfCorners;
  
      let entry = {
        MatchTime: info['Match Time'],
        HomeTeam: info['Home Team'],
        AwayTeam: info['Away Team'],
        Score: `${homeGoals}:${awayGoals}`,
        Value: 0
      };

      if (propSelection === 'Goals' && subPropSelection === 'Team Total Goals') {
        entry['Team Total Goals'] = isHomeTeam ? homeGoals : awayGoals; // Final score
        entry.Value = entry['Team Total Goals'];
      }
  
      
      switch (subPropSelection) {
        case '1st Half Goal Difference':
          entry['1st Half Goal Difference'] = isHomeTeam ? homeFirstHalfGoals - awayFirstHalfGoals : awayFirstHalfGoals - homeFirstHalfGoals;
          entry.Value = entry['1st Half Goal Difference'];
          break;
        case '2nd Half Goal Difference':
          entry['2nd Half Goal Difference'] = isHomeTeam ? homeSecondHalfGoals - awaySecondHalfGoals : awaySecondHalfGoals - homeSecondHalfGoals;
          entry.Value = entry['2nd Half Goal Difference'];
          break;
        case 'Total Goal Difference':
          entry['Total Goal Difference'] = isHomeTeam ? homeGoals - awayGoals : awayGoals - homeGoals;
          entry.Value = entry['Total Goal Difference'];
          break;
        case 'Total 1st Half Goals':
          entry['Total 1st Half Goals'] = totalFirstHalfGoals;
          entry.Value = entry['Total 1st Half Goals'];
          break;
        case 'Total 2nd Half Goals':
          entry['Total 2nd Half Goals'] = totalSecondHalfGoals;
          entry.Value = entry['Total 2nd Half Goals'];
          break;
        case 'Total Goals':
          entry['Total Goals'] = totalGoals;
          entry.Value = entry['Total Goals'];
          break;
        case 'Total 1st Half Corners':
          entry['Total 1st Half Corners'] = totalFirstHalfCorners;
          entry.Value = entry['Total 1st Half Corners'];
          break;
        case 'Total 2nd Half Corners':
          entry['Total 2nd Half Corners'] = totalSecondHalfCorners;
          entry.Value = entry['Total 2nd Half Corners'];
          break;
        case 'Total Corners':
          entry['Total Corners'] = totalCorners;
          entry.Value = entry['Total Corners'];
          break;
        case '1st Half BTTS':
            entry['1st Half BTTS'] = parseInt(info['1st Half BTTS'], 10);
            entry.Value = entry['1st Half BTTS'];
            break;
        case '2nd Half BTTS':
            entry['2nd Half BTTS'] = parseInt(info['2nd Half BTTS'], 10);
            entry.Value = entry['2nd Half BTTS'];
            break;
        case 'Total BTTS':
            entry['Total BTTS'] = parseInt(info['Total BTTS'], 10);
            entry.Value = entry['Total BTTS'];
            break;
        case 'A Team Goal':
            entry['A Team Goal'] = isHomeTeam ? homeGoals : awayGoals; // Total goals scored by the selected team
            entry.Value = entry['A Team Goal'];
            break;
        default:
            break;

      }
  
      return entry;
    });
  
    // Reverse the chartData array to display latest matches from right to left
    setFilteredTeamInfo(updatedData);
    setChartData(updatedData.filter(item => item.Value !== null).reverse());
  
    // Calculate the Hit Rate
    const hitCount = updatedData.filter(item => item.Value >= altLine).length;
    const totalCount = updatedData.length;
    const calculatedHitRate = totalCount > 0 ? (hitCount / totalCount) * 100 : 0;
    setHitRate(calculatedHitRate.toFixed(2));
  }, [teamInfo, subPropSelection, teamFilter, timeFilter, teamName, altLine]);
  


  // Custom Rectangle Component for BarChart
  const CustomRectangle = (props) => {
    const { x, y, width, height, value } = props;
    const adjustedHeight = value === 0 ? 10 : Math.abs(height); // Set height for zero and negative values
    const adjustedY = value >= 0 ? y : y + height; // Adjust y position for negative values
    const fillColor = value === 0 ? "#1e1e2f" : value > 0 ? "#4cff60" : "#ff4c60"; // Set color for zero, positive, and negative values
    return (
      <rect x={x} y={adjustedY} width={width} height={adjustedHeight} fill={fillColor} />
    );
  };


  const renderTable = () => {
    if (filteredTeamInfo.length === 0) {
      return <p>No information available for this team.</p>;
    }
  
    return (
      <div className="table-responsive">
        <table className="styled-table">
          <thead>
            <tr>
              <th>Match Time</th>
              <th>Home Team</th>
              <th>Away Team</th>
              <th>Score</th>
              {subPropSelection && <th>{subPropSelection}</th>}
            </tr>
          </thead>
          <tbody>
            {filteredTeamInfo.map((info, index) => (
              <tr key={index}>
                <td>{info.MatchTime}</td>
                <td>{info.HomeTeam}</td>
                <td>{info.AwayTeam}</td>
                <td>{info.Score}</td>
                {subPropSelection && <td>{info[subPropSelection]}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  


  const handlePropSelectionChange = (selection) => {
    setPropSelection(selection);
    setSubPropSelection('');
  };

  const handleSubPropSelectionChange = (selection) => {
    setSubPropSelection(selection);
  };

  const handleTeamFilterChange = (filter) => {
    setTeamFilter(filter);
  };

  const handleTimeFilterChange = (filter) => {
    setTimeFilter(filter);
  };

  const handleAltLineIncrease = () => {
    setAltLine(prevAltLine => prevAltLine + 0.25);
  };

  const handleAltLineDecrease = () => {
    setAltLine(prevAltLine => prevAltLine - 0.25);
  };

  const handleAltLineChange = (e) => {
    setAltLine(parseFloat(e.target.value));
  };

  const renderSubPropMenu = () => {
    switch (propSelection) {
        case 'Goal Difference':
            return (
                <div className="sub-prop-menu">
                    <button onClick={() => handleSubPropSelectionChange('1st Half Goal Difference')}>
                        1st Half Goal Difference
                    </button>
                    <button onClick={() => handleSubPropSelectionChange('2nd Half Goal Difference')}>
                        2nd Half Goal Difference
                    </button>
                    <button onClick={() => handleSubPropSelectionChange('Total Goal Difference')}>
                        Total Goal Difference
                    </button>
                </div>
            );
        case 'Goals':
            return (
                <div className="sub-prop-menu">
                  <button onClick={() => handleSubPropSelectionChange('Total 1st Half Goals')}>
                    Total 1st Half Goals
                  </button>
                  <button onClick={() => handleSubPropSelectionChange('Total 2nd Half Goals')}>
                    Total 2nd Half Goals
                  </button>
                  <button onClick={() => handleSubPropSelectionChange('Total Goals')}>
                    Total Goals
                  </button>
                  <button onClick={() => handleSubPropSelectionChange('Team Total Goals')}>
                    Team Total Goals
                  </button>
                </div>
              );
        case 'BTTS':
            return (
                <div className="sub-prop-menu">
                    <button onClick={() => handleSubPropSelectionChange('1st Half BTTS')}>1st Half BTTS</button>
                    <button onClick={() => handleSubPropSelectionChange('2nd Half BTTS')}>2nd Half BTTS</button>
                    <button onClick={() => handleSubPropSelectionChange('Total BTTS')}>Total BTTS</button>
                </div>
            );
        case 'Corners':
            return (
                <div className="sub-prop-menu">
                    <button onClick={() => handleSubPropSelectionChange('Total 1st Half Corners')}>
                        Total 1st Half Corners
                    </button>
                    <button onClick={() => handleSubPropSelectionChange('Total 2nd Half Corners')}>
                        Total 2nd Half Corners
                    </button>
                    <button onClick={() => handleSubPropSelectionChange('Total Corners')}>
                        Total Corners
                    </button>
                </div>
            );
        
        default:
            return null;
    }
};

        // case 'Team Clean Sheet':
        //     return (
        //         <div className="sub-prop-menu">
        //             <button onClick={() => handleSubPropSelectionChange('Team Clean Sheet')}>
        //                 Team Clean Sheet
        //             </button>
        //         </div>
        //     );


  const renderChart = () => {
    if (chartData.length === 0) {
      return <p>No available data to display, select a card first.</p>;
    }

    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="MatchTime" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="Value" shape={<CustomRectangle />} />
          <ReferenceLine y={altLine} stroke="grey" strokeDasharray="3 3" />
        </BarChart>
      </ResponsiveContainer>
    );
  };
  // New Tooltip component
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const { MatchTime, HomeTeam, AwayTeam, Value } = payload[0].payload;
        return (
            <div className="custom-tooltip">
                <p>{`Match Time: ${MatchTime}`}</p>
                <p>{`Home Team: ${HomeTeam}`}</p>
                <p>{`Away Team: ${AwayTeam}`}</p>
                <p>{`${subPropSelection}: ${Value}`}</p>
            </div>
        );
    }
    return null;
  };

  return (
    <div className="content-container">
        <h1 className="page-title">{teamName}</h1>
        {error && <p className="error-message">{error}</p>}

        <nav className="navbar">
            <ul className="nav-list">
                <li className={`nav-item ${propSelection === 'Goal Difference' ? 'active' : ''}`} onClick={() => handlePropSelectionChange('Goal Difference')}>
                    Goal Difference
                </li>
                <li className={`nav-item ${propSelection === 'Goals' ? 'active' : ''}`} onClick={() => handlePropSelectionChange('Goals')}>
                    Goals
                </li>

                
                <li className={`nav-item ${propSelection === 'BTTS' ? 'active' : ''}`} onClick={() => handlePropSelectionChange('BTTS')}>
                    BTTS
               </li>
                <li className={`nav-item ${propSelection === 'Corners' ? 'active' : ''}`} onClick={() => handlePropSelectionChange('Corners')}>
                    Corners
                </li>
            </ul>
        </nav>

        {renderSubPropMenu()}

        <nav className="navbar">
            <ul className="nav-list">
                <li className={`nav-item ${teamFilter === 'All' ? 'active' : ''}`} onClick={() => handleTeamFilterChange('All')}>
                    All
                </li>
                <li className={`nav-item ${teamFilter === 'Home' ? 'active' : ''}`} onClick={() => handleTeamFilterChange('Home')}>
                    Home
                </li>
                <li className={`nav-item ${teamFilter === 'Away' ? 'active' : ''}`} onClick={() => handleTeamFilterChange('Away')}>
                    Away
                </li>
            </ul>
        </nav>

        <nav className="navbar">
            <ul className="nav-list">
                <li className={`nav-item ${timeFilter === 'Last 5' ? 'active' : ''}`} onClick={() => handleTimeFilterChange('Last 5')}>
                    Last 5
                </li>
                <li className={`nav-item ${timeFilter === 'Last 10' ? 'active' : ''}`} onClick={() => handleTimeFilterChange('Last 10')}>
                    Last 10
                </li>
                <li className={`nav-item ${timeFilter === 'Last 20' ? 'active' : ''}`} onClick={() => handleTimeFilterChange('Last 20')}>
                    Last 20
                </li>
                <li className={`nav-item ${timeFilter === '22/23' ? 'active' : ''}`} onClick={() => handleTimeFilterChange('22/23')}>
                    22/23
                </li>
                <li className={`nav-item ${timeFilter === '23/24' ? 'active' : ''}`} onClick={() => handleTimeFilterChange('23/24')}>
                    23/24
                </li>
                <li className={`nav-item ${timeFilter === '24/25' ? 'active' : ''}`} onClick={() => handleTimeFilterChange('24/25')}>
                    24/25
                </li>
            </ul>
        </nav>

        <div className="alt-line-container">
            <label>Alt Lines: </label>
            <input
                type="number"
                value={altLine}
                step="0.25"
                onChange={handleAltLineChange}
                style={{ width: '60px', textAlign: 'center' }}
            />
        </div>

        <nav className="navbar">
            <ul className="nav-list">
                <li className="nav-item">
                    Hit Rate: {hitRate}%
                </li>
            </ul>
        </nav>

        {renderChart()}
        {renderTable()}
    </div>
  );
}

export default TeamInfo;