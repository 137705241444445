



import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine
} from 'recharts';
import '../Player.css'; // Import the CSS for styling

function Compare2Players() {
  const { player1, player2 } = useParams();
  const [player1Info, setPlayer1Info] = useState([]);
  const [player2Info, setPlayer2Info] = useState([]);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState({
    matchTimeCount: 'Last 5',
    yAxisVariable: 'Goals' // Default value
  });
  const [altLineValue1, setAltLineValue1] = useState(1); // Alt Lines for Player 1
  const [altLineValue2, setAltLineValue2] = useState(1); // Alt Lines for Player 2
  const [hitRate1, setHitRate1] = useState(0); // Hit Rate for Player 1
  const [hitRate2, setHitRate2] = useState(0); // Hit Rate for Player 2
  const [noDataMessage, setNoDataMessage] = useState(''); // Added: State to handle the no data message

  const propSelectionOrder = [
    'First Goal', 'Last Goal', '2 or More', '3 or More',
    'Goals', 'Assists', 'Goals + Assists', 'Shots', 'Shots on Target'
  ];

  const fetchPlayerData = useCallback(async () => {
    try {
      const response = await axios.get('https://api.betsavvy.ai/compare_2_players_info', {
        params: {
          player1: player1,
          player2: player2
        }
      });

      const { player1_info, player2_info } = response.data;

      setPlayer1Info(player1_info || []);
      setPlayer2Info(player2_info || []);
      setError('');

      // Added: Check if no data is available after fetching
      if ((player1_info || []).length === 0 && (player2_info || []).length === 0) {
        setNoDataMessage('NO AVAILABLE DATA TO SHOW.');
      } else {
        setNoDataMessage('');
      }

    } catch (error) {
      setError(`Error fetching players' info: ${error.message}`);
    }
  }, [player1, player2]);

  useEffect(() => {
    fetchPlayerData();
  }, [fetchPlayerData]);

  const applyFilters = useCallback((data) => {
    let filteredData = data;

    if (filters.matchTimeCount === '22/23') {
      filteredData = filteredData.filter(match => {
        const matchDate = new Date(match["Match Time"]);
        return matchDate >= new Date('2022-08-11') && matchDate <= new Date('2023-05-19');
      });
    } else if (filters.matchTimeCount === '23/24') {
      filteredData = filteredData.filter(match => {
        const matchDate = new Date(match["Match Time"]);
        return matchDate >= new Date('2023-08-11') && matchDate <= new Date('2024-05-19');
      });
    } else if (filters.matchTimeCount === '24/25') {
      filteredData = filteredData.filter(match => {
        const matchDate = new Date(match["Match Time"]);
        return matchDate >= new Date('2024-08-11') && matchDate <= new Date('2025-05-19');
      });
    } else if (filters.matchTimeCount.startsWith('Last')) {
      const matchCount = parseInt(filters.matchTimeCount.split(' ')[1]);
      filteredData = sortByDateDescending(filteredData).slice(0, matchCount);
    }

    return filteredData;
  }, [filters]);

  const sortByDateDescending = (data) => {
    return data.sort((a, b) => new Date(b["Match Time"]) - new Date(a["Match Time"]));
  };

  const calculateHitRates = useCallback((player1Data, player2Data) => {
    const filteredPlayer1Data = applyFilters(player1Data);
    const filteredPlayer2Data = applyFilters(player2Data);

    // Added: Update the no data message if no data is found
    if (filteredPlayer1Data.length === 0 && filteredPlayer2Data.length === 0) {
      setNoDataMessage('NO AVAILABLE DATA TO SHOW.');
    } else {
      setNoDataMessage('');
    }

    if (filteredPlayer1Data.length > 0) {
      const hits = filteredPlayer1Data.filter(match => match[filters.yAxisVariable] >= altLineValue1).length;
      setHitRate1(((hits / filteredPlayer1Data.length) * 100).toFixed(2));
    } else {
      setHitRate1(0);
    }

    if (filteredPlayer2Data.length > 0) {
      const hits = filteredPlayer2Data.filter(match => match[filters.yAxisVariable] >= altLineValue2).length;
      setHitRate2(((hits / filteredPlayer2Data.length) * 100).toFixed(2));
    } else {
      setHitRate2(0);
    }
  }, [altLineValue1, altLineValue2, applyFilters, filters.yAxisVariable]);

  useEffect(() => {
    calculateHitRates(player1Info, player2Info);
  }, [calculateHitRates, player1Info, player2Info]);

  const getChartData = (data) => {
    const filteredData = applyFilters(data);
    return filteredData.map(match => ({
      matchTime: match["Match Time"],
      homeTeam: match["Home Team"],
      awayTeam: match["Away Team"],
      value: match[filters.yAxisVariable] || 0
    })).reverse();
  };

  const renderTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { homeTeam, awayTeam, value } = payload[0].payload;

      return (
        <div className="custom-tooltip" style={{
          backgroundColor: '#ffffff',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px'
        }}>
          <p style={{ fontWeight: 'bold' }}>{`Match Time: ${label}`}</p>
          <p>{`Home Team: ${homeTeam}`}</p>
          <p>{`Away Team: ${awayTeam}`}</p>
          <p>{`${filters.yAxisVariable}: ${value}`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomRectangle = (props) => {
    const { x, y, width, height, value } = props;
    const adjustedHeight = value === 0 ? 10 : height;
    const fillColor = value === 0 ? "grey" : "#4CAF50";
    return (
      <rect x={x} y={y} width={width} height={adjustedHeight} fill={fillColor} />
    );
  };

  // Updated: Conditional rendering for no data message
  const renderChart = (data, altLineValue) => {
    if (data.length === 0) {
      return <p className="no-data-message">NO AVAILABLE DATA TO SHOW.</p>;
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={getChartData(data)} margin={{ bottom: 40 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="matchTime" />
          <YAxis />
          <Tooltip content={renderTooltip} />
          <Bar dataKey="value" fill="#4CAF50" shape={CustomRectangle} />
          <ReferenceLine 
            y={altLineValue} 
            stroke="grey" 
            strokeDasharray="0" 
            label={{
              position: 'right', 
              value: `Alt Lines: ${altLineValue}`,
              fill: 'grey',
              fontSize: 12,
            }} 
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const handleFilterChange = (event, filterType) => {
    setFilters({ ...filters, [filterType]: event.target.value });
  };

  const handleYAxisChange = (event) => {
    setFilters({ ...filters, yAxisVariable: event.target.value });
  };

  return (
    <div className="compare-container">
      {/* Header and Filters */}
      <div className="header-and-filters">
        <h1 className="header-center">Compare Players</h1>
        <div className="filters-container">
          <div className="filter-group">
            <label htmlFor="matchTimeCount">Games:</label>
            <select id="matchTimeCount" value={filters.matchTimeCount} onChange={(e) => handleFilterChange(e, 'matchTimeCount')}>
              <option value="Last 5">Last 5</option>
              <option value="Last 10">Last 10</option>
              <option value="Last 20">Last 20</option>
              <option value="22/23">22/23</option>
              <option value="23/24">23/24</option>
              <option value="24/25">24/25</option>
            </select>
          </div>
          <div className="filter-group">
            <label htmlFor="yAxisVariable">Prop Selection:</label>
            <select id="yAxisVariable" value={filters.yAxisVariable} onChange={handleYAxisChange}>
              {propSelectionOrder.map((key, index) => (
                <option key={index} value={key}>{key}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {error && <p className="error-message">{error}</p>}

      {/* Player Info and Charts */}
      <div className="player-info-container">
        <div className="player-half">
          {player1Info.length > 0 ? (
            <>
              <div className="common-info">
                <p><strong>Player Name:</strong> {player1}</p>
              </div>
              <div className="filters-container">
                <label htmlFor="altLineValue1">Alt Lines:</label>
                <input
                  type="number"
                  id="altLineValue1"
                  value={altLineValue1}
                  onChange={(e) => setAltLineValue1(parseFloat(e.target.value))}
                  step="0.1"
                />
                <div className="hit-rate">
                  <p><strong>Hit Rate:</strong> {hitRate1}%</p>
                </div>
              </div>
              {renderChart(player1Info, altLineValue1)}
              <div className="table-container">
                <table className="styled-table">
                  <thead>
                    <tr>
                      {["Match Time", "Home Team", "Away Team", "Score", filters.yAxisVariable].map((key) => (
                        <th key={key}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {applyFilters(player1Info).map((match, index) => (
                      <tr key={index}>
                        <td>{match["Match Time"]}</td>
                        <td>{match["Home Team"]}</td>
                        <td>{match["Away Team"]}</td>
                        <td>{match["Score"]}</td>
                        <td>{match[filters.yAxisVariable]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <p>{noDataMessage}</p> // Updated: Display no data message if needed
          )}
        </div>

        <div className="player-half">
          {player2Info.length > 0 ? (
            <>
              <div className="common-info">
                <p><strong>Player Name:</strong> {player2}</p>
              </div>
              <div className="filters-container">
                <label htmlFor="altLineValue2">Alt Lines:</label>
                <input
                  type="number"
                  id="altLineValue2"
                  value={altLineValue2}
                  onChange={(e) => setAltLineValue2(parseFloat(e.target.value))}
                  step="0.1"
                />
                <div className="hit-rate">
                  <p><strong>Hit Rate:</strong> {hitRate2}%</p>
                </div>
              </div>
              {renderChart(player2Info, altLineValue2)}
              <div className="table-container">
                <table className="styled-table">
                  <thead>
                    <tr>
                      {["Match Time", "Home Team", "Away Team", "Score", filters.yAxisVariable].map((key) => (
                        <th key={key}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {applyFilters(player2Info).map((match, index) => (
                      <tr key={index}>
                        <td>{match["Match Time"]}</td>
                        <td>{match["Home Team"]}</td>
                        <td>{match["Away Team"]}</td>
                        <td>{match["Score"]}</td>
                        <td>{match[filters.yAxisVariable]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <p>{noDataMessage}</p> // Updated: Display no data message if needed
          )}
        </div>
      </div>
      <Link to="/" className="back-link">Back to Player List</Link>
    </div>
  );
}

export default Compare2Players;
