import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import ReactGA from 'react-ga4'; 
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ReferenceLine
} from 'recharts';

const PageContainer = styled.div`
  padding: 20px;
  background-color: #f8f9fa; /* Light background for a cleaner look */
  min-height: 100vh;
`;

const SectionTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
`;











const CardRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 15px;
  padding-bottom: 20px;
`;

const Card = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  min-width: 160px;
  flex: 0 0 auto;
  text-align: center;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    background-color: #f0f0f0;
  }

  &.active {
    background-color: #d1e7ff;
  }

  @media (max-width: 600px) {
    min-width: 120px;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: #333;
`;

const CardDescription = styled.p`
  color: #666;
  font-size: 0.9rem;
`;

const ComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ChartContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 48%;
  }
`;

const FiltersGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;

  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
      font-size: 0.9rem;
      font-weight: bold;
      color: #333;
    }

    select {
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 0.9rem;
    }
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 20px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }

    th {
      background-color: #f4f4f4;
    }
  }
`;


const ContactLink = styled.a`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #007bff; /* A clean blue color */
  text-decoration: none;
  transition: color 0.3s ease, transform 0.2s ease;

  &:hover {
    color: #0056b3;
    transform: scale(1.1);
  }
`;

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    // Use the selectedProp to dynamically display the sub-option name
    const subOptionName = data.selectedProp || 'Value';
    const subOptionValue = data[subOptionName] !== undefined ? data[subOptionName] : 'N/A';

    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <p><strong>Match Time:</strong> {data.MatchTime}</p>
        <p><strong>Home Team:</strong> {data.HomeTeam}</p>
        <p><strong>Away Team:</strong> {data.AwayTeam}</p>
        <p>
          <strong>{subOptionName}:</strong> {subOptionValue}
        </p>
      </div>
    );
  }
  return null;
};

// Custom Rectangle Component for BarChart
const CustomRectangle = (props) => {
  const { x, y, width, height, value } = props;
  const adjustedHeight = value === 0 ? 10 : Math.abs(height);
  const adjustedY = value >= 0 ? y : y + height;
  const fillColor = value === 0 ? '#1e1e2f' : value > 0 ? '#4cff60' : '#ff4c60';
  return <rect x={x} y={adjustedY} width={width} height={adjustedHeight} fill={fillColor} />;
};

const TodaysMatches = () => {
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [team1Filters, setTeam1Filters] = useState({
    matchTimeCount: 'Last 5',
    propSelection: 'Goal Difference',
    subPropSelection: 'Total Goal Difference',
    teamFilter: 'Overall',
  });
  const [team2Filters, setTeam2Filters] = useState({
    matchTimeCount: 'Last 5',
    propSelection: 'Goal Difference',
    subPropSelection: 'Total Goal Difference',
    teamFilter: 'Overall',
  });
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [hitRate1, setHitRate1] = useState(0);
  const [hitRate2, setHitRate2] = useState(0);
  const [altLine1, setAltLine1] = useState(1);
  const [altLine2, setAltLine2] = useState(1);
  const [error, setError] = useState('');





  
  const matches = useMemo(
    () => [

      { date: '12/14/2024', teams: 'Arsenal vs. Everton' },
      { date: '12/14/2024', teams: 'Liverpool vs. Fulham' },
      { date: '12/14/2024', teams: 'Newcastle United vs. Leicester City' },
      { date: '12/14/2024', teams: 'Wolverhampton Wanderers vs. Ipswich Town' },
      { date: '12/14/2024', teams: 'Nottingham Forest vs. Aston Villa' },

      { date: '12/15/2024', teams: 'Brighton & Hove Albion vs. Crystal Palace' },
      { date: '12/15/2024', teams: 'Manchester City vs. Manchester United' },
      { date: '12/15/2024', teams: 'Chelsea vs. Brentford' },
      { date: '12/15/2024', teams: 'Southampton vs. Tottenham Hotspur' },

      { date: '12/22/2024', teams: 'Manchester United vs. AFC Bournemouth' },
      { date: '12/22/2024', teams: 'Tottenham Hotspur vs. Liverpool' },

      { date: '12/27/2024', teams: 'Brighton & Hove Albion vs. Brentford' },
      { date: '12/27/2024', teams: 'Arsenal vs. Ipswich Town' },

      { date: '12/30/2024', teams: 'Manchester United vs. Newcastle United' },


      { date: '01/01/2025', teams: 'Brentford vs. Arsenal' },
      { date: '01/05/2025', teams: 'Liverpool vs. Manchester United' },
      { date: '01/06/2025', teams: 'Wolverhampton Wanderers vs. Nottingham Forest' },

      { date: '01/16/2025', teams: 'Ipswich Town vs. Brighton & Hove Albion' },
      { date: '01/16/2025', teams: 'Manchester United vs. Southampton' },
      { date: '01/19/2025', teams: 'Ipswich Town vs. Manchester City' },
      { date: '01/20/2025', teams: 'Chelsea vs. Wolverhampton Wanderers' },

    ],
    []
  );

  useEffect(() => {
    if (matches.length > 0 && selectedTeams === null) {
      const [team1, team2] = matches[0].teams.split(' vs. ');
      setSelectedTeams({ team1: team1.trim(), team2: team2.trim() });
      setActiveIndex(0);
    }
  }, [matches, selectedTeams]);

  useEffect(() => {
    if (selectedTeams) {
      fetchTeamData(selectedTeams.team1, selectedTeams.team2);
    }
  }, [selectedTeams, team1Filters, team2Filters, altLine1, altLine2]);

  useEffect(() => {
    if (selectedTeams) {
      // Track event when a new match is selected
      ReactGA.event({
        category: 'User',
        action: 'Selected Match',
        label: `${selectedTeams.team1} vs. ${selectedTeams.team2}`,
      });
    }
  }, [selectedTeams]);

  const handleCardClick = (teams, index) => {
    const [team1, team2] = teams.split(' vs. ');
    setSelectedTeams({ team1: team1.trim(), team2: team2.trim() });
    setActiveIndex(index);
  };

  const fetchTeamData = async (team1, team2) => {
    if (!team1 || !team2) {
      setError('Invalid team selection');
      return;
    }

    try {
      const response1 = await axios.get(
        `https://api.betsavvy.ai/teamlist_info/${encodeURIComponent(team1)}`
      );
      const response2 = await axios.get(
        `https://api.betsavvy.ai/teamlist_info/${encodeURIComponent(team2)}`
      );

      // Log the responses to verify the data structure
      console.log('Team 1 Data:', response1.data);
      console.log('Team 2 Data:', response2.data);

      const sortedData1 = response1.data.sort(
        (a, b) => new Date(b['Match Time']) - new Date(a['Match Time'])
      );
      const sortedData2 = response2.data.sort(
        (a, b) => new Date(b['Match Time']) - new Date(a['Match Time'])
      );

      processTeamData(sortedData1, setChartData1, setHitRate1, altLine1, team1Filters, selectedTeams.team1);
      processTeamData(sortedData2, setChartData2, setHitRate2, altLine2, team2Filters, selectedTeams.team2);
    } catch (error) {
      setError(`Error fetching team info: ${error.message}`);
    }
  };

  const processTeamData = (teamData, setChartData, setHitRate, altLine, filters, teamName) => {
    let filteredData = teamData;
  
    // Apply Team Filter (Home, Away, Overall)
    filteredData = filteredData.filter((info) => {
      if (filters.teamFilter === 'Home') return info['Home Team'] === teamName;
      if (filters.teamFilter === 'Away') return info['Away Team'] === teamName;
      return true; // Overall
    });
  
    // Process data to ensure no duplicates
    const uniqueData = new Map();
    filteredData.forEach((info) => {
      const key = `${info['Match Time']}-${info['Home Team']}-${info['Away Team']}`;
      if (!uniqueData.has(key)) {
        let entry = {
          MatchTime: info['Match Time'],
          HomeTeam: info['Home Team'],
          AwayTeam: info['Away Team'],
          Score: `${info['Home Goals Count'] || 0}:${info['Away Goals Count'] || 0}`,
        };
  
        // Determine selectedProp (subPropSelection takes precedence)
        const selectedProp = filters.subPropSelection || filters.propSelection;
  
        if (selectedProp === 'Team Total Goals') {
          entry['Team Total Goals'] = info['Home Team'] === teamName
            ? parseInt(info['Home Goals Count'] || 0, 10) // Goals scored by Home Team
            : parseInt(info['Away Goals Count'] || 0, 10); // Goals scored by Away Team
          entry.Value = entry['Team Total Goals']; // Assign for charts
        } else {
          // Switch for other sub-prop selections
          switch (selectedProp) {
            case '1st Half Goal Difference':
              entry.Value =
                parseInt(info['Home 1st Half Goals'] || 0, 10) -
                parseInt(info['Away 1st Half Goals'] || 0, 10);
              break;
            case '2nd Half Goal Difference':
              entry.Value =
                parseInt(info['Home 2nd Half Goals'] || 0, 10) -
                parseInt(info['Away 2nd Half Goals'] || 0, 10);
              break;
            case 'Total Goal Difference':
              entry.Value =
                parseInt(info['Home Goals Count'] || 0, 10) -
                parseInt(info['Away Goals Count'] || 0, 10);
              break;
            case 'Total 1st Half Goals':
              entry.Value =
                parseInt(info['Home 1st Half Goals'] || 0, 10) +
                parseInt(info['Away 1st Half Goals'] || 0, 10);
              break;
            case 'Total 2nd Half Goals':
              entry.Value =
                parseInt(info['Home 2nd Half Goals'] || 0, 10) +
                parseInt(info['Away 2nd Half Goals'] || 0, 10);
              break;
            case 'Total Goals':
              entry.Value =
                parseInt(info['Home Goals Count'] || 0, 10) +
                parseInt(info['Away Goals Count'] || 0, 10);
              break;
            case 'Total 1st Half Corners':
              entry.Value =
                parseInt(info['Home 1st Half Corners'] || 0, 10) +
                parseInt(info['Away 1st Half Corners'] || 0, 10);
              break;
            case 'Total 2nd Half Corners':
              entry.Value =
                parseInt(info['Home 2nd Half Corners'] || 0, 10) +
                parseInt(info['Away 2nd Half Corners'] || 0, 10);
              break;
            case 'Total Corners':
              entry.Value =
                (parseInt(info['Home 1st Half Corners'] || 0, 10) +
                  parseInt(info['Away 1st Half Corners'] || 0, 10)) +
                (parseInt(info['Home 2nd Half Corners'] || 0, 10) +
                  parseInt(info['Away 2nd Half Corners'] || 0, 10));
              break;
            case '1st Half BTTS':
              entry.Value =
                parseInt(info['Home 1st Half Goals'] || 0, 10) > 0 &&
                parseInt(info['Away 1st Half Goals'] || 0, 10) > 0
                  ? 1
                  : 0;
              break;
            case '2nd Half BTTS':
              entry.Value =
                parseInt(info['Home 2nd Half Goals'] || 0, 10) > 0 &&
                parseInt(info['Away 2nd Half Goals'] || 0, 10) > 0
                  ? 1
                  : 0;
              break;
            case 'Total BTTS':
              entry.Value =
                parseInt(info['Home Goals Count'] || 0, 10) > 0 &&
                parseInt(info['Away Goals Count'] || 0, 10) > 0
                  ? 1
                  : 0;
              break;
            default:
              entry.Value = null;
          }
        }
  
        entry[selectedProp] = entry.Value;
        uniqueData.set(key, entry);
      }
    });
  
    // Convert unique data to an array
    let finalData = Array.from(uniqueData.values());
  
    // Apply Match Time Filter
    if (filters.matchTimeCount === 'Last 5') {
      finalData = finalData.slice(0, 5);
    } else if (filters.matchTimeCount === 'Last 10') {
      finalData = finalData.slice(0, 10);
    } else if (filters.matchTimeCount === 'Last 20') {
      finalData = finalData.slice(0, 20);
    }
  
    // Reverse the data for latest-to-earliest display
    finalData.reverse();
  
    // Update Chart Data
    setChartData(finalData.filter((item) => item.Value !== null));
  
    // Calculate Hit Rate
    const hitCount = finalData.filter((item) => item.Value >= altLine).length;
    setHitRate(((hitCount / finalData.length) * 100).toFixed(2));
  };
  



  const renderSubPropMenu = (filters, setFilters) => {
    switch (filters.propSelection) {
      case 'Goal Difference':
        return (
          <div className="filter-group">
            <label>Sub Prop:</label>
            <select
              value={filters.subPropSelection}
              onChange={(e) => setFilters((prev) => ({ ...prev, subPropSelection: e.target.value }))}
            >
              <option value="1st Half Goal Difference">1st Half Goal Difference</option>
              <option value="2nd Half Goal Difference">2nd Half Goal Difference</option>
              <option value="Total Goal Difference">Total Goal Difference</option>
            </select>
          </div>
        );
      case 'Goals':
        return (
          <div className="filter-group">
            <label>Sub Prop:</label>
            <select
              value={filters.subPropSelection || 'Total Goals'}
              onChange={(e) => setFilters((prev) => ({ ...prev, subPropSelection: e.target.value }))}
            >
              <option value="Total 1st Half Goals">Total 1st Half Goals</option>
              <option value="Total 2nd Half Goals">Total 2nd Half Goals</option>
              <option value="Total Goals">Total Goals</option>
              <option value="Team Total Goals">Team Total Goals</option>
            </select>
          </div>
        );
      case 'Corners':
        return (
          <div className="filter-group">
            <label>Sub Prop:</label>
            <select
              value={filters.subPropSelection || 'Total Corners'}
              onChange={(e) => setFilters((prev) => ({ ...prev, subPropSelection: e.target.value }))}
            >
              <option value="Total 1st Half Corners">Total 1st Half Corners</option>
              <option value="Total 2nd Half Corners">Total 2nd Half Corners</option>
              <option value="Total Corners">Total Corners</option>
            </select>
          </div>
        );
        case 'BTTS':
          return (
            <div className="filter-group">
              <label>Sub Prop:</label>
              <select
                value={filters.subPropSelection || 'BTTS'}
                onChange={(e) => setFilters((prev) => ({ ...prev, subPropSelection: e.target.value }))}
              >
                <option value="1st Half BTTS">1st Half BTTS</option>
                <option value="2nd Half BTTS">2nd Half BTTS</option>
                <option value="Total BTTS">Total BTTS</option>
              </select>
            </div>
          );
        
        default:
          return null;
      }
    };

  const renderFilters = (filters, setFilters, label) => (
    <FiltersGroup>
      <div className="filter-group">
        <label>Games ({label}):</label>
        <select
          value={filters.matchTimeCount}
          onChange={(e) => setFilters((prev) => ({ ...prev, matchTimeCount: e.target.value }))}
        >
          <option value="Last 5">Last 5</option>
          <option value="Last 10">Last 10</option>
          <option value="Last 20">Last 20</option>
          <option value="22/23">22/23</option>
          <option value="23/24">23/24</option>
          <option value="24/25">24/25</option>
        </select>
      </div>
      <div className="filter-group">
        <label>Prop Selection ({label}):</label>
        <select
          value={filters.propSelection}
          onChange={(e) => {
            const newPropSelection = e.target.value;
            let defaultSubProp = '';
            if (newPropSelection === 'Goals') defaultSubProp = 'Total Goals';
            if (newPropSelection === 'Corners') defaultSubProp = 'Total Corners';
            if (newPropSelection === 'Goal Difference') defaultSubProp = 'Total Goal Difference';
            if (newPropSelection === 'BTTS') defaultSubProp = 'Total BTTS';

            setFilters((prev) => ({
              ...prev,
              propSelection: newPropSelection,
              subPropSelection: defaultSubProp,
            }));
          }}
        >
          <option value="Goal Difference">Goal Difference</option>
          <option value="Goals">Goals</option>
          <option value="Corners">Corners</option>
          <option value="BTTS">BTTS</option>
        </select>
      </div>
      {renderSubPropMenu(filters, setFilters)}
      <div className="filter-group">
        <label>Team Filter ({label}):</label>
        <select
          value={filters.teamFilter}
          onChange={(e) => setFilters((prev) => ({ ...prev, teamFilter: e.target.value }))}
        >
          <option value="Overall">Overall</option>
          <option value="Home">Home</option>
          <option value="Away">Away</option>
        </select>
      </div>
    </FiltersGroup>
  );

  const renderChart = (chartData, altLine) => {
    if (chartData.length === 0) {
      return <p>No available data to display, select a match first.</p>;
    }

    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="MatchTime" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="Value" shape={<CustomRectangle />} />
          <ReferenceLine y={altLine} stroke="grey" strokeDasharray="3 3" />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const renderTable = (chartData, filters) => (
    <TableContainer>
      <table>
        <thead>
          <tr>
            <th>Match Time</th>
            <th>Home Team</th>
            <th>Away Team</th>
            <th>Score</th>
            <th>{filters.subPropSelection || filters.propSelection}</th>
          </tr>
        </thead>
        <tbody>
          {chartData.map((data, index) => (
            <tr key={index}>
              <td>{data.MatchTime}</td>
              <td>{data.HomeTeam}</td>
              <td>{data.AwayTeam}</td>
              <td>{data.Score}</td>
              <td>{data[filters.subPropSelection || filters.propSelection]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );

  const handleAltLineChange = (e, setAltLine) => {
    setAltLine(parseFloat(e.target.value));
  };

  return (
    <PageContainer>
      <p style={{ position: 'absolute', top: '20px', right: '20px', fontSize: '1rem', color: '#333' }}>
        Complaints? Reach us on{' '}
        <ContactLink
          href="https://x.com/Bet__Savvy"
          target="_blank"
          rel="noopener noreferrer"
          title="Contact us on X"
        >
          X
        </ContactLink>
      </p>

      <SectionTitle>Today's Matches</SectionTitle>
      {error && <p className="error-message">{error}</p>}
      <CardRow>
        {matches.map((match, index) => (
          <Card
            key={index}
            onClick={() => handleCardClick(match.teams, index)}
            className={index === activeIndex ? 'active' : ''}
          >
            <CardTitle>{match.date}</CardTitle>
            <CardDescription>{match.teams}</CardDescription>
          </Card>
        ))}
      </CardRow>

      <ComparisonContainer>
        <ChartContainer>
          <h2>{selectedTeams?.team1}</h2>
          {renderFilters(team1Filters, setTeam1Filters, 'Team 1')}
          <div className="alt-line-container">
            <label>Alt Line: </label>
            <input
              type="number"
              value={altLine1}
              step="0.25"
              onChange={(e) => handleAltLineChange(e, setAltLine1)}
              style={{ width: '60px', textAlign: 'center' }}
            />
          </div>
          <p>Hit Rate: {hitRate1}%</p>
          {renderChart(chartData1, altLine1)}
          {renderTable(chartData1, team1Filters)}
        </ChartContainer>

        <ChartContainer>
          <h2>{selectedTeams?.team2}</h2>
          {renderFilters(team2Filters, setTeam2Filters, 'Team 2')}
          <div className="alt-line-container">
            <label>Alt Line: </label>
            <input
              type="number"
              value={altLine2}
              step="0.25"
              onChange={(e) => handleAltLineChange(e, setAltLine2)}
              style={{ width: '60px', textAlign: 'center' }}
            />
          </div>
          <p>Hit Rate: {hitRate2}%</p>
          {renderChart(chartData2, altLine2)}
          {renderTable(chartData2, team2Filters)}
        </ChartContainer>
      </ComparisonContainer>
    </PageContainer>
  );
};

export default TodaysMatches;





