


import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Initialize Google Analytics
const initializeGoogleAnalytics = () => {
  // Load the Google Analytics script
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-FEFZR19FY9';
  script.async = true;
  document.head.appendChild(script);

  // Configure Google Analytics
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-FEFZR19FY9');
  };
};

// Call the function to initialize Google Analytics
initializeGoogleAnalytics();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


reportWebVitals();
